<template>
    <div>
        <div class="l-inline l-center-v l-gap-2 filter-actions">
            <i18n path="showOf">
                <b>{{ filteredTrackers.length }}</b>
                <b>{{ trackers.length }}</b>
            </i18n>

            <a v-if="isClearFilterVisible" @click="$emit('clearFilter')">
                {{ $t('clearFilter') }}
            </a>
        </div>

        <table
            v-infinite-scroll="loadMoreTrackers"
            infinite-scroll-distance="25"
        >
            <tr>
                <th>
                    <input type="checkbox" @change="selectAll" />
                </th>

                <th>{{ $t('icon') }}</th>

                <th>{{ $t('name') }}</th>

                <th>{{ $t('type') }}</th>

                <th>{{ $t('location') }}</th>

                <th>{{ $t('identifier') }}</th>
            </tr>

            <tr
                v-for="tracker in trackersPortion"
                :key="tracker.id"
                class="clickable"
                @click="selected = [tracker.asset]"
            >
                <td>
                    <input
                        v-model="selected"
                        type="checkbox"
                        :value="tracker.asset"
                        @click.stop
                    />
                </td>

                <td>
                    <asset-avatar :tracker="tracker" :size="32" />
                </td>

                <td>
                    {{ tracker.asset_details.name }}
                </td>

                <td>
                    {{ getTrackerTypeTranslated(tracker) }}
                </td>

                <td>
                    {{
                        tracker.location_details &&
                            tracker.location_details.name
                    }}
                </td>

                <td>
                    {{ tracker.identifier }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import AssetAvatar from '@/components/AssetAvatar'

const defaultScrollLimit = 15

export default {
    name: 'DashboardAssetsTableView',
    components: { AssetAvatar },
    directives: {
        infiniteScroll,
    },
    props: {
        filterColor: {
            type: Array,
            default: () => [],
        },
        filterLocation: {
            type: Array,
            default: () => [],
        },
        filterSearch: {
            type: String,
            default: '',
        },
        filterType: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selected: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes', 'trackers']),
        filteredTrackers() {
            let result = this.trackers

            if (this.filterSearch) {
                result = result.filter(
                    tracker =>
                        tracker.asset_details?.name
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase()) ||
                        tracker.deveui
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase()) ||
                        tracker.asset_details?.identification
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase())
                )
            }

            if (this.filterColor.length) {
                result = result.filter(tracker =>
                    this.filterColor.includes(tracker.asset_details.color)
                )
            }

            if (this.filterLocation.length) {
                result = result.filter(tracker =>
                    this.filterLocation.some(
                        location => location.id === tracker.location
                    )
                )
            }

            if (this.filterType.length) {
                result = result.filter(tracker =>
                    this.filterType.some(
                        type => type.id === tracker.asset_details.asset_type
                    )
                )
            }
            return result
        },
        trackersPortion() {
            return this.filteredTrackers.length === this.trackers.length
                ? this.filteredTrackers.slice(0, this.scrollLimit)
                : this.filteredTrackers
        },
        isClearFilterVisible() {
            return (
                this.filterColor.length ||
                this.filterLocation.length ||
                this.filterSearch ||
                this.filterType.length
            )
        },
    },
    watch: {
        selected(value) {
            this.$emit('update:selected', value)
        },
    },
    methods: {
        getTrackerTypeTranslated(tracker) {
            const translationKey = `shared.types.${tracker.asset_details.asset_type_identifier}`

            return this.$root.$i18n.te(translationKey)
                ? this.$t(translationKey)
                : this.assetTypes.find(
                      type => type.id === tracker.asset_details.asset_type
                  )?.name
        },
        loadMoreTrackers() {
            if (this.scrollLimit < this.trackers.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        selectAll(event) {
            this.selected = event.target.checked
                ? this.filteredTrackers.map(tracker => tracker.asset)
                : []
        },
    },
}
</script>

<i18n>
{
    "en": {
        "clearFilter": "Clear filter",
        "icon": "Farbe",
        "identifier": "Identifier",
        "location": "Location",
        "name": "Name",
        "showOf": "Show {0} of {1}",
        "type": "Type"
    },
    "de": {
        "clearFilter": "Filter zurücksetzen",
        "icon": "Farbe",
        "identifier": "Identifikation",
        "location": "Standort",
        "name": "Name",
        "showOf": "Zeige {0} of {1}",
        "type": "Typ"
    },
    "it": {
        "clearFilter": "Annullare il filtro",
        "icon": "Farbe",
        "identifier": "Identifier",
        "location": "Locazione",
        "name": "Name",
        "showOf": "Listo {0} di {1}",
        "type": "Type"
    }
}
</i18n>

<style lang="scss" scoped>
.filter-actions {
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    background-color: #fff;
    border-bottom: $style-border;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);

    a {
        font-size: 13px;
        color: #000000;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    tr {
        &.clickable {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter;
            }
        }

        th {
            padding: 1rem;
            text-align: left;
        }

        td {
            padding: 0.5rem 1rem;
            border-top: $style-border;
        }

        th,
        td {
            &:first-child {
                padding-left: 2rem;
            }

            &:not(:last-child),
            &:not(:last-child) {
                width: 1%;
                white-space: nowrap;
            }
        }
    }
}
</style>
