<template>
    <div class="dashboard-assets-view">
        <div class="dashboard-assets-view__filters">
            <base-input
                v-model="filterSearch"
                :debounce="400"
                :placeholder="$t('filterByQuery')"
            >
                <template #icon>
                    <search-icon width="16" height="16" />
                </template>
            </base-input>

            <v-multiselect
                v-model="filterType"
                class="multiselect-redesigned multiselect-redesigned--block"
                :options="assetTypes"
                track-by="id"
                label="name"
                multiple
                hide-selected
                :close-on-select="false"
                :show-labels="false"
                :placeholder="$t('filterByType')"
            >
                <template v-slot:noResult>{{
                    $t('shared.noSearchResult')
                }}</template>
            </v-multiselect>

            <v-multiselect
                v-model="filterLocation"
                class="multiselect-redesigned multiselect-redesigned--block"
                :options="getLocationsSortedByName"
                track-by="id"
                label="name"
                multiple
                hide-selected
                :close-on-select="false"
                :show-labels="false"
                :placeholder="$t('filterByLocation')"
            >
                <template v-slot:noResult>{{
                    $t('shared.noSearchResult')
                }}</template>
            </v-multiselect>

            <color-input v-model="filterColor" class="redesigned" />
        </div>

        <div
            class="dashboard-assets-view__split"
            :class="{ 'dashboard-assets-view__split--active': selected.length }"
        >
            <dashboard-assets-table-view
                :selected.sync="selected"
                :filter-color="filterColor"
                :filter-location="filterLocation"
                :filter-search="filterSearch"
                :filter-type="filterType"
                @clearFilter="clearFilter"
            />

            <dashboard-assets-edit-view
                v-if="selected.length"
                :asset-ids="selected"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import VMultiselect from 'vue-multiselect'

import BaseInput from '../redesigned/BaseInput'
import ColorInput from '../ColorInput'
import DashboardAssetsEditView from './DashboardAssetsEditView'
import DashboardAssetsTableView from './DashboardAssetsTableView'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'DashboardAssetsView',
    components: {
        BaseInput,
        ColorInput,
        DashboardAssetsEditView,
        DashboardAssetsTableView,
        SearchIcon,
        VMultiselect,
    },
    data() {
        return {
            selected: [],
            filterColor: [],
            filterLocation: [],
            filterSearch: '',
            filterType: [],
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
    },
    methods: {
        clearFilter() {
            this.filterColor = []
            this.filterLocation = []
            this.filterSearch = ''
            this.filterType = []
        },
    },
}
</script>

<i18n>
{
    "en": {
        "filterByColor": "Color",
        "filterByLocation": "Location",
        "filterByQuery": "Name",
        "filterByType": "Type"
    },
    "de": {
        "filterByColor": "Farbe",
        "filterByLocation": "Standort",
        "filterByQuery": "Name",
        "filterByType": "Typ"
    },
    "it": {
        "filterByColor": "Colore",
        "filterByLocation": "Locazione",
        "filterByQuery": "Name",
        "filterByType": "Typo"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-assets-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input,
        .multiselect {
            max-width: 200px;
        }
    }

    &__split {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        div {
            width: 375px;
            height: 100%;
            overflow-y: auto;

            &:first-child {
                width: 100%;
            }
        }

        &--active {
            div {
                &:first-child {
                    width: calc(100% - 375px);
                }

                &:last-child {
                    border-left: $style-border;
                }
            }
        }
    }

    @include respond-to('for-mobile-down') {
        &__split {
            flex-direction: column-reverse;

            div {
                width: 100% !important;
                height: auto;
                border-left: none !important;
                flex: 1 0 100%;

                &:first-child {
                    border-top: $style-border;
                }
            }
        }
    }
}
</style>
